import React from "react";

const TrendingTools = () => {
	return (
		<div>
			<h1>Home</h1>
		</div>
	);
};

export default TrendingTools;
