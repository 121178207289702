import React from "react";

const Vulnerabilities = () => {
	return (
		<div>
			<h1>This site contains tons of vulnerabilities btw. </h1>
		</div>
	);
};

export default Vulnerabilities;
